@import "@src/assets/styles/mixin";

$mobile-layout: 468px;
$button-style-breakpoint: 768px;
$medium-layout: 860px;
$desktop-layout: 1080px;
$action-menu-rollup-computed-width: 40px;

.Polaris-Page-Header {
  padding: var(--p-space-4);
}

.Polaris-Page-Header__Row {
  position: relative;
}

.BookingFlowPage {
  &>.CustomPageOverrides {
    h1.Polaris-Header-Title {
      color: #000000;
      text-align: center;

      @media screen and (min-width: 768px) {
        @include gtSuperDisplay(normal, 700, 42px, 53px);
      }

      @media screen and (max-width: 767px) {
        @include gtSuperDisplay(normal, 700, 26px, 35px);
      }
    }
  }
}

.BreadcrumbWrapper {
  position: absolute;
  top: 0;
  left: 0;
}
