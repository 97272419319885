@import "@src/assets/styles/mixin";

.schedule-tankless {
	@include breakpoint-after($p-breakpoints-lg) {
		margin-top: -36px;
	}

	@include breakpoint-before($p-breakpoints-lg) {
		margin-top: -25px;
	}

	.calendar-loading {
		margin-top: 80px;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
