@import "@src/assets/styles/mixin";

.TankCatalogFilter {
  height: 100px;
  padding: 20px;
  background-color: var(--hb-color-snow);
  box-shadow: 0 2px 1px rgb(0 0 0 / 5%), 0 0 1px rgb(0 0 0 / 25%);
  border-radius: 8px;
  display: flex;
  align-items: center;

  @include breakpoint-before($p-breakpoints-lg) {
    border-radius: 0;
  }

  &Logo {
    :global {
      .Polaris-Thumbnail {
        background-color: var(--hb-color-surface-neutral-subdued);
      }
    }
  }

  &Content {
    @include inter(normal, 600, 16px, 24px);

    display: flex;
    margin-left: 20px;
    flex-direction: column;

    &Title {
      @include inter(normal, 600, 12px, 16px);

      color: var(--hb-color-light-gray);
      text-transform: uppercase;
    }
  }

  :global {
    .Polaris-Button {
      margin-left: auto;
      width: 50px;
      height: 30px;
    }
  }
}
