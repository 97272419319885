@import "@src/assets/styles/mixin";

.frequently-asked-questions {
    @include forceFullWidth();

    background: var(--hb-color-snow);

    h2 {
        @include gtSuperDisplay(normal, 700, 42px, 53px);
        color: var(--hb-color-obsidian);
        text-align: center;
    }

    @media screen and (min-width: 992px) {
        margin-top: 88px;
        padding: 88px 16px 110px;
    }

    @media screen and (max-width: 991px) {
        margin-top: 64px;
        padding: 64px 16px;
    }

    section {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media screen and (min-width: 1440px) {
            width: 100%;
            max-width: 1440px;
            padding: 0 240px;
            margin: 0 auto;
        }

        & > .description {
            margin-top: 22px;
            text-align: center;

            @media screen and (min-width: 992px) {
                max-width: 634px;
                @include gtAmerica(normal, 400, 20px, 28px);
            }

            @media screen and (max-width: 991px) {
                @include inter(normal, 400, 15px, 20px);
            }
        }

        .question-list {
            width: 100%;

            @media screen and (min-width: 992px) {
                margin-top: 72px;
            }

            @media screen and (max-width: 991px) {
                margin-top: 32px;
            }
        }
    }
}
