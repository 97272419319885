@import "@src/assets/styles/mixin";

.CustomFrameOverrides {
  .Polaris-TopBar-Menu__Activator {
    padding: 0;
    margin: 0;
  }

  .Polaris-TopBar-Menu__ActivatorWrapper {
    height: fit-content;
    margin: auto;
  }
}
