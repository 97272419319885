@import "@src/assets/styles/mixin";

.gray {
  --hb-text-color: var(--hb-color-text-default);
  --hb-theme-color: var(--hb-color-border-subdued);
  --hb-theme-color-button: transparent;
  --hb-theme-color-button-border: var(--hb-color-border-subdued);
  --hb-theme-color-reverse: var(--hb-color-text-default);
}

.yellow {
  --hb-text-color: var(--hb-color-text-default);
  --hb-theme-color: var(--frame-header-bg-color);
  --hb-theme-color-button: var(--frame-header-bg-color);
  --hb-theme-color-button-border: transparent;
  --hb-theme-color-reverse: var(--hb-color-text-default);
}

.blue {
  --hb-text-color: var(--hb-color-text-default);
  --hb-theme-color: var(--hb-color-pacific);
  --hb-theme-color-button: var(--hb-color-pacific);
  --hb-theme-color-button-border: transparent;
  --hb-theme-color-reverse: var(--hb-color-snow);
}

.card {
  @include sfProText(normal, normal, rem(14px), rem(16px));

  background-color: var(--hb-color-snow);
  color: var(--hb-text-color);
  position: relative;
  width: rem(299px);

  :global {
    .Polaris-Card {
      padding: rem(20px);
      height: rem(436px);
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }

  & + & {
    @include breakpoint-after($p-breakpoints-lg) {
      margin-left: 32px;
    }

    @include breakpoint-before($p-breakpoints-lg) {
      margin-top: rem(20px);
    }
  }

  &Popular {
    position: relative;
    width: 100%;

    :global {
      .Polaris-Badge {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        margin-top: rem(-26px);
        padding: rem(2px) rem(8px);
        background-color: var(--hb-theme-color);
        color: var(--hb-theme-color-reverse);
        white-space: nowrap;

        & > span {
          font-size: rem(13px);
        }
      }
    }
  }

  @include breakpoint-before($p-breakpoints-lg) {
    width: rem(343px);

    :global {
      .Polaris-Card {
        height: unset;
      }
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    width: 100%;
    height: rem(8px);
    background-color: var(--hb-theme-color);
    border-radius: rem(8px) rem(8px) 0 0;
  }

  &Header {
    margin-top: rem(8px);
    padding-bottom: rem(20px);

    &Title > h3 {
      font-size: rem(16px);
      font-weight: 600;
      line-height: rem(24px);
      margin-bottom: rem(12px);
    }

    &Price {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: rem(4px);
      font-size: rem(12px);
      font-weight: 400;
      color: var(--hb-color-text-subdued);

      h3 {
        font-size: rem(20px);
        font-weight: 400;
        line-height: rem(28px);
        color: var(--hb-color-text-default);
      }
    }
  }

  &Body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: var(--hb-color-text-default);
    font-size: rem(14px);
    font-weight: 400;
    line-height: rem(20px);

    &Features {
      list-style: none;
      padding: 0;
      width: 100%;
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      margin-bottom: rem(32px);

      :global {
        .Polaris-Badge {
          background-color: transparent;
          align-items: flex-start;
          padding: 0;

          & > span {
            font-size: rem(14px);
            line-height: rem(20px);
          }
        }
      }

      &Feature {
        display: flex;

        &:not(:last-child) {
          margin-bottom: rem(12px);
        }
      }
    }
  }

  &Footer {
    width: 100%;

    :global {
      .Polaris-Button {
        padding: rem(12px) rem(24px);
        border-radius: rem(4px);
        border-color: var(--hb-theme-color-button-border);
        background-color: var(--hb-theme-color-button);
        color: var(--hb-theme-color-reverse);

        &:hover {
          background-color: var(--hb-theme-color-button);
        }
      }
    }
  }
}
