@import "@src/assets/styles/mixin";

section.customers-love-us {
    @include forceFullWidth();

    text-align: center;

    h2 {
        @include gtSuperDisplay(normal, 700, 42px, 53px);
        color: var(--hb-color-obsidian);
        text-align: center;
    }

    @media screen and (min-width: 992px) {
        margin-top: 88px;
    }

    @media screen and (max-width: 991px) {
        margin-top: 64px;
    }

    .description {
        color: rgba(0, 0, 0, 0.6);

        display: inline-flex;
        align-items: center;

        @media screen and (min-width: 992px) {
            margin-top: 22px;
            @include gtAmerica(normal, 400, 20px, 32px);
        }

        @media screen and (max-width: 991px) {
            margin-top: 12px;
            @include inter(normal, 400, 15px, 20px);
        }

        & > img:first-child {
            margin-right: 8px;
        }
    }

    .social-reviews {
        max-width: 892px;
        margin: 0 auto;
    }
}
