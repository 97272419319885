@import "@src/assets/styles/mixin";

.FilterModal {
  &Header {
    @include sfProText(normal, normal, rem(20px), rem(28px));

    padding: var(--p-space-5);
    margin: calc(var(--p-space-5) * -1);
  }

  &Choices {
    :global {
      .Polaris-ChoiceList {
        &:not(:last-child) {
          margin-bottom: rem(20px);
        }

        .Polaris-ChoiceList__Title {
          margin-bottom: rem(12px);
        }

        .Polaris-ChoiceList__ChoiceItem {
          margin-bottom: 0;
        }
      }
    }
  }
}
