@use "sass:math";
@import "./breakpoints.scss";

$html-font-size: 16px;

@function rem($pxValue) {
  @return math.div($pxValue, $html-font-size) * 1rem;
}

@function breakpoint($value) {
  $value-unit: unit($value);

  @if $value-unit == "rem" {
    @return $value;
  } @else if $value-unit == "px" {
    @return rem($value);
  } @else {
    @error 'The $value passed into breakpoint() must be a pixel or em value. Got "#{$value}"';
  }
}

@mixin breakpoint-after($breakpoint) {
  @media (min-width: #{breakpoint($breakpoint)}) {
    @content;
  }
}

@mixin breakpoint-before($breakpoint) {
  @media (max-width: #{breakpoint($breakpoint - 0.0025rem)}) {
    @content;
  }
}

@mixin inter($style, $weight, $size, $lineHeight: normal) {
  font-family: "Inter", sans-serif !important;
  font-style: $style !important;
  font-weight: $weight !important;
  font-size: $size !important;
  line-height: $lineHeight !important;
}

@mixin sfProText($style, $weight, $size, $lineHeight: normal) {
  font-family: "SF Pro Text", sans-serif !important;
  font-style: $style !important;
  font-weight: $weight !important;
  font-size: $size !important;
  line-height: $lineHeight !important;
}

@mixin gtSuperDisplay($style, $weight, $size, $lineHeight: normal) {
  font-family: "GT Super Display", sans-serif !important;
  font-style: $style !important;
  font-weight: $weight !important;
  font-size: $size !important;
  line-height: $lineHeight !important;
}

@mixin gtAmerica($style, $weight, $size, $lineHeight: normal) {
  font-family: "GT America", sans-serif !important;
  font-style: $style !important;
  font-weight: $weight !important;
  font-size: $size !important;
  line-height: $lineHeight !important;
}

@mixin textShrink($maxLine: 1) {
  text-overflow: ellipsis;
  overflow: hidden;

  @if $maxLine==1 {
    word-break: break-all;
  }

  display: -webkit-box;
  -webkit-line-clamp: $maxLine;
  -webkit-box-orient: vertical;
}

@mixin forceFullWidth() {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}
