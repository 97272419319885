@import "@src/assets/styles/mixin";

.BookingFlow {
  & .Polaris-TopBar {
    background: var(--frame-header-bg-color);

    @include breakpoint-after($p-breakpoints-lg) {
      padding: var(--p-space-8);
    }

    @include breakpoint-before($p-breakpoints-lg) {
      padding: var(--p-space-3) var(--p-space-5);
      min-height: 4rem;
    }
  }

  .Polaris-TopBar__LogoContainer {
    margin: auto;

    @include breakpoint-after($p-breakpoints-lg) {
      padding: var(--p-space-2) var(--p-space-4) var(--p-space-2) var(--p-space-10);
    }

    @include breakpoint-before($p-breakpoints-lg) {
      padding: 0;
    }
  }

  .Polaris-TopBar,
  .Polaris-Frame__TopBar {
    height: fit-content;
    align-items: center;
    justify-content: center;
  }
}
