@import "./mixin";

:root {
  --hb-color-pacific: #0627F9; // almost the same as --p-text-primary-hovered
  --hb-color-obsidian: #22252D;
  --hb-color-grey-sky: rgba(34 37 45 / 70%);
  --hb-color-sunset: #F9D806;
  --hb-color-snow: #FFF;
  --hb-color-background: #F8F8F8;
  --hb-color-inactive: #D3D3D5;
  --hb-color-error: #F91506;

  --hb-color-text-default: #202223;
  --hb-color-text-subdued: #6D7175;
  --hb-color-text-critical: #D72C0D;

  --hb-color-border-subdued: #C9CCCF;
  --hb-color-border-neutral-subdued: #BABFC3;

  --hb-color-interactive-critical: #D82C0D;

  --hb-color-surface-default: #FFF;
  --hb-color-surface-neutral-subdued: #F6F6F7;

  --p-action-primary: var(--hb-color-pacific);
  --p-action-primary-hovered: rgba(6, 33, 203, 1);
  --p-action-primary-pressed: rgba(8, 28, 157, 1);
  --p-action-primary-depressed: rgba(10, 23, 110, 1);
  --p-text-primary: rgba(76, 84, 254, 1);
  --p-text-primary-hovered: rgba(49, 63, 253, 1);
  --p-text-primary-pressed: rgba(6, 38, 245, 1);
  --p-surface-primary-selected: rgba(246, 246, 248, 1);
  --p-surface-primary-selected-hovered: rgba(200, 200, 214, 1);
  --p-surface-primary-selected-pressed: rgba(180, 181, 199, 1);
  --p-surface-highlight-subdued: rgba(6, 39, 249, 0.03);
  --p-border-base: 1px solid rgba(6, 39, 249, 0.3);
  --frame-header-bg-color: var(--hb-color-sunset);
}
