@import "@src/assets/styles/mixin";

.job-list-container {
    padding-top: 20px;

    .no-job {
        padding: 106px 16px;
        font-family: var(--p-font-family-sans);
        font-style: normal;
        text-align: center;

        &--title {
            font-weight: var(--p-font-weight-semibold);
            font-size: 21px;
            line-height: var(--p-font-line-height-4);
            color: var(--p-text);
        }

        &--description {
            margin-top: var(--p-space-5);
            font-weight: var(--p-font-weight-regular);
            font-size: 15px;
            line-height: var(--p-font-line-height-2);
            color: var(--p-text-subdued);
        }
    }
}
