@font-face {
  font-family: "SF Pro Text";
  src: url("../fonts/SF-Pro-Text-Regular.otf") format("opentype");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Text";
  src: url("../fonts/SF-Pro-Text-Medium.otf") format("opentype");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Text";
  src: url("../fonts/SF-Pro-Text-Semibold.otf") format("opentype");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "GT Super Display";
  src: url("../fonts/GT-Super-Display-Bold.otf") format("opentype");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "GT America";
  src: url("../fonts/GT-America-Standard-Regular.otf") format("opentype");
  font-weight: 400;
  font-display: swap;
}
