.TanklessMobileCatalogFilter {
  &Choices {
    background: var(--hb-color-snow);
    border: 1px solid var(--hb-color-border-neutral-subdued);
    border-radius: 8px;
    box-shadow: 2px 1px rgb(0 0 0 / 5%), 0 0 1px rgb(0 0 0 / 25%);
    width: 100%;
    padding: 20px;
  }

  :global {
    .Polaris-ChoiceList {
      &:not(:last-child) {
        margin-bottom: 20px;
      }

      .Polaris-ChoiceList__Title {
        margin-bottom: 12px;
      }

      .Polaris-ChoiceList__ChoiceItem {
        margin-bottom: 0;
      }
    }

    .Polaris-Button {
      margin-bottom: 12px;

      &:hover {
        background-color: var(--hb-color-snow);
      }
    }
  }
}
