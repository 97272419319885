@import "@src/assets/styles/mixin";

.expandable-container {
    position: relative;

    .btn-expand {
        position: absolute;
        padding: 10px;
        border: none;
        background: none;
    }

    padding: 16px 0;

    .btn-expand {
        right: 0;

        @media screen and (min-width: 992px) {
            top: 24px;
        }

        @media screen and (max-width: 991px) {
            top: 24px;
        }
    }

    & > .title {
        margin-top: 16px;
        max-width: calc(100% - 64px);
        color: var(--hb-color-obsidian);

        @media screen and (min-width: 992px) {
            @include gtSuperDisplay(normal, 700, 28px, 53px);
        }

        @media screen and (max-width: 991px) {
            @include gtSuperDisplay(normal, 700, 28px, 32px);
        }
    }

    & > .description {
        margin-top: 16px;
        @include gtAmerica(normal, 400, 20px, 28px);
        color: rgba(34, 37, 45, 0.7);

        ul {
            list-style: inside;
        }
    }
}

.expandable-container:not(.expanded) {
    .btn-expand > img {
        transform: rotate(180deg);
    }

    .btn-expand + * + * {
        display: none;
    }
}

.expandable-container + .expandable-container {
    border-top: 1px solid var(--hb-color-inactive);
}
