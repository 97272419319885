.pdf-viewer-modal-wrapper {
  * {
    overflow: hidden;
  }

  .react-pdf__Page__canvas {
    width: 100% !important;
    height: 100% !important;
  }
}
