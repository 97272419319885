:root {
  --p-font-family-mono: 'Inter', sans-serif !important;
  --p-font-family-sans: 'Inter', sans-serif !important;
}

.caption-text {
	font-family: var(--p-font-family-sans);
	font-weight: var(--p-font-weight-medium);
	font-size: var(--p-font-size-1);
	line-height: var(--p-line-height-1);
	color: var(--p-text);

	a {
		color: var(--p-text-primary);
	}
}
