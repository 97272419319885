@import "@src/assets/styles/mixin";

.ChooseProduct {
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;

  &Loading {
    width: 100%;
    height: rem(336px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &> * {
    width: 100%;
  }

  &Content {
    max-width: 960px;

    @include breakpoint-before($p-breakpoints-lg) {
      display: flex;
      flex-direction: column;
    }

    > * {
      @include breakpoint-after($p-breakpoints-lg) {
        margin-top: rem(32px);
      }

      @include breakpoint-before($p-breakpoints-lg) {
        margin-top: rem(20px);
      }

      &:last-child {
        @include breakpoint-after($p-breakpoints-lg) {
          margin-top: rem(64px);
        }

        @include breakpoint-before($p-breakpoints-lg) {
          margin-top: rem(57px);
        }
      }
    }
  }

  &List {
    display: flex;
    justify-content: center;

    @include breakpoint-before($p-breakpoints-lg) {
      flex-direction: column;
      align-items: center;
    }

    &Empty {
      width: 100%;
      height: rem(336px);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      box-shadow: 0 2px 1px rgb(0 0 0 / 5%), 0 0 1px rgb(0 0 0 / 25%);
      border-radius: 8px;
      background-color: var(--hb-color-snow);

      @include breakpoint-before($p-breakpoints-lg) {
        padding: 0 2rem;
      }

      &Title {
        @include inter(normal, 400, rem(20px), rem(28px));

        text-align: center;
      }

      &Description {
        @include inter(normal, 400, rem(14px), rem(20px));

        text-align: center;
        margin-top: rem(16px);
        color: var(--hb-color-light-gray);
      }
    }
  }

  &Customized {
    align-self: center;
  }
}
