@import "./color.scss";
@import "./typography.scss";
@import "./mixin";
@import "../fonts/fonts";

.small-screen {
  @include breakpoint-after($p-breakpoints-lg) {
    display: none !important;
  }

  @include breakpoint-before($p-breakpoints-lg) {
    display: block !important;
  }
}

.not-small-screen {
  @include breakpoint-after($p-breakpoints-lg) {
    display: block !important;
  }

  @include breakpoint-before($p-breakpoints-lg) {
    display: none !important;
  }
}
