@import "@src/assets/styles/mixin";

.ChooseProduct {
  min-height: 100vh;

  &Main {
    @media screen and (min-width: 1440px) {
      max-width: 1440px;
      padding: 0 240px;
      margin: 0 auto;
    }
  }

  &CategorySelect {
    @media screen and (min-width: 992px) {
      margin-top: 32px;
    }

    @media screen and (max-width: 991px) {
      margin-top: 16px;
    }
  }

  &Incentives {
    margin: 32px 0;
  }

  &Loading {
    width: 100%;
    height: rem(336px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &List {
    @media screen and (min-width: 992px) {
      margin-top: 32px;
    }

    @media screen and (max-width: 991px) {
      margin-top: 20px;
    }

    &Empty {
      width: 100%;
      height: 336px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      box-shadow: 0 2px 1px rgb(0 0 0 / 5%), 0 0 1px rgb(0 0 0 / 25%);
      border-radius: 8px;
      background-color: var(--hb-color-snow);

      @include breakpoint-before($p-breakpoints-lg) {
        padding: 0 2rem;
      }

      &Title {
        text-align: center;
        @include inter(normal, 400, 20px, 28px);
      }

      &Description {
        text-align: center;
        margin-top: 16px;
        color: var(--hb-color-light-gray);
        @include inter(normal, 400, 14px, 20px);
      }
    }
  }
}

.CallUs {
  @include forceFullWidth();

  background: var(--hb-color-snow);
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    @include gtSuperDisplay(normal, 700, 42px, 53px);

    color: var(--hb-color-obsidian);
    text-align: center;

    &:nth-child(2) {
      @media screen and (min-width: 992px) {
        margin-top: 32px;
      }

      @media screen and (max-width: 991px) {
        margin-top: 20px;
      }
    }
  }

  @media screen and (min-width: 992px) {
    margin-top: 92px;
    padding: 88px 0;
  }

  @media screen and (max-width: 991px) {
    margin-top: 64px;
    padding: 64px 0;
  }

  div {
    color: rgb(0 0 0 / 60%);

    > a {
      color: var(--hb-color-pacific);
    }

    @media screen and (min-width: 992px) {
      margin-top: 22px;
      @include gtAmerica(normal, 400, 20px, 32px);
    }

    @media screen and (max-width: 991px) {
      margin-top: 20px;
      @include inter(normal, 400, 15px, 20px);
    }
  }
}
