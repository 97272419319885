.pdf-viewer-wrapper {
    margin-top: 2px;
    margin-left: calc(var(--pc-stack-spacing) * -1);

    .react-pdf__Document {
        --pc-stack-spacing: var(--p-space-4);
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        margin-left: 0;
    }

    .react-pdf__Page {
        margin-right: 0.5rem;
        display: block;
        overflow: hidden;
        background: var(--p-surface);
        min-width: var(--pc-thumbnail-extra-small-size);
        max-width: 100%;
        border-radius: var(--p-border-radius-1);
        border: var(--p-border-divider);
    }

    .react-pdf__Page__canvas {
        width: 78px !important;
        height: 78px !important;
    }
}
