@import "@src/assets/styles/mixin";

.card {
  @include gtSuperDisplay(normal, 700, rem(40px), 100%);
  @include forceFullWidth();

  background-color: var(--hb-color-snow);
  min-height: rem(249px);
  display: flex;
  flex-direction: column;
  color: var(--hb-color-text-default);
  padding: rem(40px);
  align-items: center;
  border-radius: 0;

  @include breakpoint-before($p-breakpoints-lg) {
    @include gtSuperDisplay(normal, 700, rem(26px), rem(35px));

    padding: rem(32px);
    min-height: rem(147px);
  }

  &Body {
    margin-top: rem(32px);

    @include breakpoint-before($p-breakpoints-lg) {
      margin-top: rem(16px);
    }
  }
}
