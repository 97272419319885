@import "@src/assets/styles/mixin";

.BookingFlow {
  .Polaris-Frame--hasTopBar .Polaris-Frame__Main {
    @include breakpoint-after($p-breakpoints-lg) {
      padding-top: 6.75rem;
    }

    @include breakpoint-before($p-breakpoints-lg) {
      padding-top: 4rem;
    }
  }
}
