@import "@src/assets/styles/_mixin.scss";

.black {
  --hb-text-color: var(--hb-color-text-default);
  --hb-color-text-subdued: var(--hb-color-light-gray);
  --hb-theme-color: var(--hb-color-obsidian);
  --hb-theme-color-button: var(--hb-color-text-default);
  --hb-theme-color-button-border: var(--hb-color-text-default);
  --hb-theme-color-reverse: var(--hb-color-snow);
}

.card {
  @include inter(normal, normal, rem(14px), rem(20px));

  background-color: var(--hb-color-snow);
  color: var(--hb-text-color);
  width: rem(464px);
  height: rem(180px);
  position: relative;

  :global {
    .Polaris-Card {
      padding: rem(20px);
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  @include breakpoint-before($p-breakpoints-lg) {
    width: rem(343px);
    height: 100%;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    width: 100%;
    height: rem(8px);
    background-color: var(--hb-theme-color);
    border-radius: rem(8px) rem(8px) 0 0;
  }

  &Header {
    margin-bottom: rem(12px);

    &Title > h3 {
      @include inter(normal, 600, rem(16px), rem(24px));
    }
  }

  &Body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    color: var(--hb-color-text-subdued);
    margin-bottom: rem(32px);
  }

  &Footer {
    width: 100%;

    :global {
      .Polaris-Button {
        padding: rem(12px) rem(24px);
        border-radius: 4px;
        border-color: var(--hb-theme-color-button-border);
        background-color: var(--hb-theme-color-button);
        color: var(--hb-theme-color-reverse);

        @include breakpoint-before($p-breakpoints-lg) {
          width: 100%;
        }

        &:hover {
          background-color: var(--hb-theme-color-button);
        }
      }
    }
  }
}
