@import "@src/assets/styles/mixin";

.summary-page-container {
  position: relative;
  margin: auto;

  .Polaris-Layout {
    @include breakpoint-after($p-breakpoints-lg) {
      max-width: 1440px !important;
      margin: 0 auto;
      padding: 0 16%;
    }

    @include breakpoint-before($p-breakpoints-lg) {
      padding: 0 16px;
    }

    &__Section {
      & + .Polaris-Layout__Section {
        min-width: 330px;
      }
    }
  }

  .primaryActionWrapper {
    text-align: center !important;

    .Polaris-Button {
      @include gtAmerica(normal, 400, 20px, 32px);

      padding: 1rem 3.25rem;

      @include breakpoint-after($p-breakpoints-lg) {
        width: 14rem;
        height: 4rem;
      }

      @include breakpoint-before($p-breakpoints-lg) {
        width: 100%;
      }
    }
  }

  .caption-text {
    color: #000;
    opacity: 0.6;
    margin-bottom: 42px;
  }
}

.page-isloading {
  display: flex;
  justify-content: center;
  align-items: center;
}
