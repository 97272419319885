@import "@src/assets/styles/mixin";

.Polaris-Button__Content {
    font-size: var(--p-font-size-3) !important;
    line-height: 1.25rem;
}

.Polaris-Button.appt-details .Polaris-Button__Content {
    line-height: 1.25rem !important;
}

.serviceAppointmentTime {
    padding-top: 0 !important;
}

.Polaris-Card__Footer {
    @include breakpoint-before($p-breakpoints-lg) {
        justify-content: flex-start !important;
    }

    @include breakpoint-after($p-breakpoints-lg) {
        display: none !important;
    }

    button {
        padding: 0 !important;
        background: transparent !important;
        border-color: transparent !important;
        box-shadow: none !important;
        max-height: var(--pc-button-slim-min-height) !important;
    }
}
