@import "@src/assets/styles/mixin";

.TanklessDesktopCatalogFilter {
  display: flex;

  :global {
    .Polaris-Button {
      @include inter(normal, 400, 14px, 20px);

      background-color: var(--hb-color-snow);
      border-radius: 0;
      width: 100%;
    }

    div {
      width: 100%;
      white-space: nowrap;

      &:not(:first-child) .Polaris-Button {
        border-left: 0;
      }

      &:first-child .Polaris-Button {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &:last-child .Polaris-Button {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
}

:global {
  .Polaris-Popover {
    &__Section p {
      @include inter(normal, 600, 12px, 16px);

      text-transform: uppercase;
      color: var(--hb-color-light-gray);
    }

    & button {
      @include sfProText(normal, normal, 13px, 16px);

      color: var(--hb-color-text-default);
    }
  }
}
