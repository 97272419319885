@import "@src/assets/styles/mixin";

.product-list-item {
	position: relative;
	background-color: var(--hb-color-snow);
	border-radius: 8px;
	position: relative;
	box-shadow: 0 2px 1px rgba(0, 0, 0, 0.05), 0 0 1px rgba(0, 0, 0, 0.25);

	& + & {
		margin-top: 20px;
	}

	.popular {
		> .Polaris-Badge {
			background-color: var(--frame-header-bg-color);
			color: var(--hb-color-text-default);
			white-space: nowrap;
			position: absolute;
			top: 20px;
			left: 20px;

			& span {
				@include sfProText(normal, normal, 13px, 16px);
			}

			@media screen and (min-width: 768px) {
				left: calc(200px + 3.3% + 20px)
			}
		}

		& ~ .info {
			margin-top: 40px;
		}
	}

	@media screen and (min-width: 768px) {
		padding: 20px;

		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}

	@media screen and (max-width: 767px) {
		padding: 16px;
	}

	.avatar {
		flex: 0 0 auto;
		background-color: var(--p-background);
		padding: 12px;

		justify-content: center;
		align-items: center;

		div.water-heater {
			display: flex;

			img {
				width: 176px;
				height: auto;

				@media screen and (max-width: 767px) {
					width: 50%;
					margin: auto;
				}
			}
		}
	}

	.info {
		@media screen and (min-width: 768px) {
			flex: auto;
			margin: 0 3.3%;
		}

		@media screen and (max-width: 767px) {
			margin-top: 32px;
		}

		.name {
			color: var(--hb-color-text-default);

			@media screen and (min-width: 768px) {
				@include inter(normal, 600, 28px, 32px);
			}

			@media screen and (max-width: 767px) {
				@include inter(normal, 600, 24px, 28px);
			}
		}

		.description {
			@media screen and (min-width: 768px) {
				margin-top: 20px;
				@include inter(normal, normal, 15px, 20px);
				color: var(--hb-color-text-default);
			}

			@media screen and (max-width: 767px) {
				display: none;
			}
		}

		.item-list {
			margin-top: 8px;

			.item {
				padding: 12px 0 16px;
				display: flex;
				justify-content: space-between;
				align-items: flex-start;

				&:not(:last-child) {
					border-bottom: 1px solid var(--hb-color-border-neutral-subdued);
				}

				& > span:nth-child(1) {
					@include inter(normal, 600, 12px, 16px);
					text-transform: uppercase;
					@include textShrink(1);
				}

				& > span:nth-child(2) {
					@include inter(normal, 400, 14px, 20px);
					@include textShrink(1);
				}
			}
		}
	}

	.actions {
		@media screen and (min-width: 768px) {
			flex: 0 0 auto;
			width: 200px;
		}

		@media screen and (max-width: 767px) {
			margin-top: 20px;
		}

		.price-value {
			@include inter(normal, 400, 26px, 32px);
		}

		.price-description {
			margin-top: 8px;
			@include inter(normal, 400, 12px, 16px);
			a {
				color: var(--hb-color-text-default);
				text-decoration: underline;
			}
		}

		.Polaris-Button--primary {
			margin-top: 20px;
			width: 100%;
			background: var(--hb-color-pacific);
			border-radius: 4px;
		}

		.pay-upon-installation {
			@include inter(normal, 400, 12px, 16px);
			color: rgba(34, 37, 45, 0.7);
			text-align: center;
			margin-top: 10px;
			width: 100%;
		}

		.tags {
			margin-top: 20px;

			@media screen and (max-width: 767px) {
				margin-bottom: 20px;
			}

			& > div:nth-child(1) {
				@include inter(normal, 600, 12px, 16px);
				text-transform: uppercase;
			}

			.tag-list {
				margin: 12px -4px -4px;
				display: flex;

				flex-wrap: wrap;

				.Polaris {
					&-Badge {
						margin: 4px;

						& span {
							@include sfProText(normal, normal, 13px, 16px);
						}
					}

					&-Icon {
						display: flex;
						margin-left: 4px;
						vertical-align: middle;
					}
				}
			}
		}
	}
}
