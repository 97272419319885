@import "@src/assets/styles/mixin";

.ProApp {
  & > .CustomFrameOverrides {
    h1.Polaris-Header-Title {
      @include inter(normal, 600, 24px, 28px);

      color: var(--hb-color-text-default);
    }
  }

  .Polaris-Frame--hasTopBar .Polaris-Frame__Main {
    @include breakpoint-after($p-breakpoints-lg) {
      padding-top: 3.75rem;
    }

    @include breakpoint-before($p-breakpoints-lg) {
      padding-top: 4rem;
    }
  }
}
