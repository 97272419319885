.contact-info {
  h2.Polaris-Heading {
    padding-bottom: var(--p-space-4);
  }
}

.gap-6 {
  gap: 1.5rem;
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid {
  display: grid;
}

.mb-4 {
  margin-bottom: 1rem;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.sm\:col-span-2 {
  @media (min-width: 640px) {
    grid-column: span 2 / span 2;
  }
}

.sm\:col-span-1 {
  @media (min-width: 640px) {
    grid-column: span 1 / span 1;
  }
}
