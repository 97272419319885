@import "@src/assets/styles/mixin";

.ProductCatalogFilter {
  padding: 0;

  &Desktop {
    @include breakpoint-before($p-breakpoints-lg) {
      display: none;
    }
  }

  &Mobile {
    @include breakpoint-after($p-breakpoints-lg) {
      display: none;
    }
  }
}
