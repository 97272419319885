@import "@src/assets/styles/mixin";

.success-page {
    & h1.Polaris-Header-Title {
        @media screen and (min-width: 768px) {
          @include gtSuperDisplay(normal, 700, 42px, 53px);

          position: unset !important;
        }
    }

    &-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include breakpoint-after($p-breakpoints-lg) {
            margin-top: 12.5rem !important;
            max-width: 1440px;
            margin: 0 auto;
            padding: 0 16%;
        }

        @include breakpoint-before($p-breakpoints-lg) {
            margin-top: 8.25rem !important;
            padding: 0 16px;
        }

        & > * {
            max-width: 100%;
        }
    }

    .description {
        margin-top: 12px;
        color: var(--hb-color-grey-sky);

        @include breakpoint-after($p-breakpoints-lg) {
            @include inter(normal, 400, 14px, 20px);
        }

        @include breakpoint-before($p-breakpoints-lg) {
            @include inter(normal, 400, 15px, 20px);
            text-align: center;
        }
    }

    .message {
        margin-top: 32px;
        width: 400px;
        padding: 16px;
        background: var(--hb-color-surface-neutral-subdued);
        border: 1px solid var(--hb-color-border-neutral-subdued);
        border-radius: 8px;
        @include inter(normal, 400, 14px, 20px);
        color: var(--hb-color-obsidian);
    }

    @include breakpoint-after($p-breakpoints-lg) {
        .Polaris-Button {
            margin: 3.75rem 0;
            width: 14rem;
            height: 4rem;
        }
    }

    @include breakpoint-before($p-breakpoints-lg) {
        .Polaris-Button {
            margin-top: 2rem;
            width: 100%;
        }
    }
}
