@import "@src/assets/styles/mixin";

.selected-product {
  @include inter(normal, 400, 12px, 16px);

  color: var(--hb-color-grey-sky);
  background-color: var(--hb-color-snow);
  box-shadow: 0 2px 1px rgba(0 0 0 / 5%), 0 0 1px rgba(66 71 76 / 45%);
  border-radius: 16px;
  padding: 20px;

  .product-name {
    @include gtAmerica(normal, 400, 20px, 28px);
    color: var(--hb-color-obsidian);
  }

  button {
    padding: 4px 8px;
    background: var(--hb-color-snow);
  }

  .product-price {
    font-weight: 600;
    text-transform: uppercase;
  }
  & > .Polaris-Stack.Polaris-Stack--distributionEqualSpacing:not(:first-child) {
    margin-top: -4px;
  }

  .sub-heading {
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 20px;
    &-include {
      margin: 20px 0;
      padding-top: 20px;
      border-top: 1px solid var(--hb-color-inactive);
    }
  }

  .Polaris-Badge {
    color: var(--hb-color-obsidian);
    background-color: transparent;
  }
}

.summary-rebate {
  display: flex;
  align-items: center;
  cursor: pointer;
}

